import React from "react"
import { graphql, Link } from "gatsby"

import {
  Accreditations,
  BulletListWithIcon,
  Card,
  CheckatradeInfo,
  HeaderCentered,
  ImageSection,
  Layout,
  Pill,
  Seo,
} from "../components/Shared"

import { CardSection } from "../components/Sections"

const WhyUs = ({ data }) => (
  <Layout>
    <Seo
      url={data.site && data.site.siteMetadata.url + "/why-us"}
      title={data.headerAndSeo.seo && data.headerAndSeo.seo.title}
      description={data.headerAndSeo.seo.metaDescription.metaDescription}
      image={
        data.headerAndSeo.seo &&
        data.headerAndSeo.seo.image &&
        data.headerAndSeo.seo.image.file &&
        data.headerAndSeo.seo.image.file.url
      }
    />
    <HeaderCentered heading={data.headerAndSeo.headerTitle} />
    <ImageSection
      blobColour="blue"
      image={data.section1.image.fluid}
      imageDescription={data.section1.image.title}
      direction="text-right"
    >
      <h2 className="heading-large">{data.section1.heading}</h2>
      <p>{data.section1.paragraph.paragraph}</p>
      <BulletListWithIcon bullets={data.section1.bullets} />
      <h4 className="heading-extra-small">Certifications</h4>
      <Accreditations FCAIncluded={false} />
    </ImageSection>
    <ImageSection
      image={data.section2.image.fluid}
      imageDescription={data.section2.image.title}
    >
      <h2 className="heading-large">{data.section2.heading}</h2>
      <p>{data.section2.paragraph.paragraph}</p>
      <BulletListWithIcon bullets={data.section2.bullets} />
      <CheckatradeInfo />
    </ImageSection>
    <CardSection
      heading="Flexible ways to pay"
      paragraph="Not every repair is the same, so why should the way you pay be? Can't find what you're looking for? Call us on 0191 406 0888"
      additionalClasses="ways-to-pay"
    >
      <Card preHeading="Pay monthly" heading="Home repairs cover">
        <Pill text="Unlimited repairs" />
        <p>
          No matter the cost, no matter the problem, we repair your property
          within your monthly plan.
        </p>
        <BulletListWithIcon
          bullets={[
            "Find a plan that suits your needs",
            "Oustanding customer service",
            "Never worry about expensive breakages again",
          ]}
        />
        <Link to="/home-cover-plans" className="button-primary">
          Choose your plan
        </Link>
      </Card>
      <Card preHeading="Pay monthly" heading="Boiler financing">
        <Pill text="Interest-free available" />
        <p>
          Don’t fancy dipping into your savings for a new boiler? No problem.
          Pay us back with 12 - 120 monthly instalments.
        </p>
        <BulletListWithIcon
          bullets={[
            "Best boilers on the market",
            "Book your installation online",
            "Answer 10 simple questions and get your quote today",
          ]}
        />
        <Link to="/quote" className="button-primary">
          Get a new boiler
        </Link>
      </Card>
      <Card preHeading="Pay as you go" heading="One-off repairs">
        <Pill text="Expert tradespeople" />
        <p>
          Prefer paying up-front? We do that too! Head to one of our service
          pages below to find out how we can help.
        </p>
        <div className="one-off-services">
          {data.services.edges.map(({ node }) => (
            <Link
              to={`/services/${node.slug}`}
              className="button-secondary"
              key={node.id}
            >
              {node.name}
            </Link>
          ))}
        </div>
      </Card>
    </CardSection>
  </Layout>
)

export default WhyUs

export const pageQuery = graphql`
  query getWhyUsContent {
    site {
      siteMetadata {
        url
      }
    }
    section1: contentfulPageSections(
      contentful_id: { eq: "3sX91lZmmOnPq1KWkhmKqV" }
    ) {
      contentful_id
      image {
        fluid(maxWidth: 2000) {
          ...GatsbyContentfulFluid
        }
        title
      }
      bullets
      heading
      paragraph {
        paragraph
      }
      updatedAt
    }
    section2: contentfulPageSections(
      contentful_id: { eq: "6RtKrzG5hFwuCB5Klu8V5a" }
    ) {
      contentful_id
      image {
        fluid(maxWidth: 2000) {
          ...GatsbyContentfulFluid
        }
        title
      }
      bullets
      heading
      paragraph {
        paragraph
      }
      updatedAt
    }
    headerAndSeo: contentfulPages(page: { eq: "Why us" }) {
      contentful_id
      page
      seo {
        image {
          file {
            url
          }
        }
        metaDescription {
          metaDescription
        }
        title
      }
      headerTitle
    }
    services: allContentfulServicePages(
      filter: { id: { ne: "a86865ba-b742-5fb1-a81f-200fe9183980" } }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          id
          slug
          name
        }
      }
    }
  }
`
